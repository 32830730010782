<template>
  <div>
    <el-form
      :label-position="'left'"
      label-width="80px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 80%; min-width: 800px"
    >
      <el-form-item label="id">
        <el-input v-model="flexQRCode.id" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="flexQRCode.title"></el-input>
      </el-form-item>
      <el-form-item label="正文">
        <el-input placeholder="正文内容" v-model="flexQRCode.text"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          v-model="flexQRCode.remark"
          :rows="8"
        ></el-input>
      </el-form-item>
      <el-form-item label="活码">
        <el-row v-for="(item, index) in flexQRCode.weChats" :key="index">
          <div class="group">
            <div>微信号:<el-input v-model="item.wechatId"></el-input></div>
            <div>二维码：<el-input v-model="item.url"></el-input></div>
            <div>
              状态：<el-input
                v-model="item.status"
                placeholder="1:有效、0:无效"
              ></el-input>
            </div>
            <div>
              备用微信<el-input v-model="item.backupWechatId"></el-input>
            </div>
          </div>
          <el-row style="margin-top: 10px">
            <el-button @click="addWeChat(index)" size="mini"
              >插入微信</el-button
            >
            <el-button @click="delWeChat(index)" size="mini">删除</el-button>
          </el-row>
        </el-row>
      </el-form-item>
    </el-form>

    <el-form
      :label-position="'left'"
      label-width="80px"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item class="bottomBtn">
        <el-button @click="onAdd">保存</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { alertMessage } from "@/util/alertMessageUtil";
import { flexQRCodeApi } from "@/api/flexQRCodeApi.js";

export default {
  components: {},
  data() {
    return {
      flexQRCode: {
        id: "",
        title: "",
        text: "",
        updateTime: 0,
        remark: "",
        weChats: [
          {
            wechatId: "",
            url: "",
            status: 1,
            backupWechatId: ""
          }
        ]
      }
    };
  },
  methods: {
    addWeChat(index) {
      let obj = {
        wechatId: "",
        url: ""
      };
      this.flexQRCode.weChats.splice(index + 1, 0, obj);
    },
    delWeChat(index) {
      if (this.flexQRCode.weChats.length === 1) {
        alertMessage("不能再删了", "，warning");
        return;
      }
      this.flexQRCode.weChats.splice(index, 1);
    },
    onAdd() {
      if (
        !this.flexQRCode.title ||
        !this.flexQRCode.text ||
        this.invalidWeChat()
      ) {
        alertMessage("输入不合法", "，warning");
        return;
      }
      flexQRCodeApi
        .save(this.flexQRCode)
        .then(ret => {
          console.log(ret);
          if (ret.code == 0) {
            alertMessage("保存成功", "success");
            this.flexQRCode = ret.data;
            if (!this.flexQRCode.weChats) {
              this.flexQRCode.weChats = [
                {
                  wechatId: "",
                  url: "",
                  status: 1,
                  backupWechatId: ""
                }
              ];
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    invalidWeChat() {
      for (let item of this.flexQRCode.weChats) {
        if (!item.wechatId || !item.url) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    if (this.$route.params.id) {
      flexQRCodeApi.getById(this.$route.params.id).then(ret => {
        console.log("---ret.data.data", ret.data);
        if (ret.code === 0) {
          this.flexQRCode = ret.data;
          if (!this.flexQRCode.weChats) {
            this.flexQRCode.weChats = [
              {
                wechatId: "",
                url: "",
                status: 1,
                backupWechatId: ""
              }
            ];
          }
        } else {
          alertMessage("获取数据失败，请稍后再试", "warning");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.group {
  display: flex;
  flex-direction: row;
}
</style>
